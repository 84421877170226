import PhoneIcon from "@heroicons/react/24/outline/PhoneIcon";
import EnvelopeIcon from "@heroicons/react/24/outline/EnvelopeIcon";
import MapPinIcon from "@heroicons/react/24/outline/MapPinIcon";

function Design() {
  return (
    <div className="grid place-items-center w-full bg-base-100 pb-48 pt-10 ">
      <div className=" content-center w-full max-w-6xl">
        <h1 className="text-2xl  leading-10 font-bold mb-5 max-sm:m-5">
          Our Studio
        </h1>

        <div className="w-full md:px-0 px-4 grid grid-cols-2 max-sm:grid-cols-1 gap-10">
          <div>
            <div
              className="bg-gray-300 rounded-xl min-h-72"
              style={{
                backgroundImage: "url(/studio.jpg)",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            ></div>
            <div>
              <div></div>
              <h1 className="text-2xl  leading-10 font-bold mb-5 mt-10">
                Facilities
              </h1>

              <div className="flex flex-col gap-5">
                <div className="flex gap-3 w-full max-sm:flex-col">
                  <div
                    className="bg-gray-300 rounded-xl min-h-20 max-h-32 w-[40%] max-sm:w-full max-sm:min-h-64"
                    style={{
                      backgroundImage: "url(/alat_chair.jpg)",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  ></div>
                  <div className="w-full pl-10 py-3">
                    <div className="font-semibold">Chair</div>
                    <div>3 pax</div>
                    <div className="text-justify">
                      The Pilates chair is a compact piece of equipment used to
                      build core strength, balance, and flexibility. It features
                      a platform with a spring-loaded pedal, allowing for a
                      variety of exercises that challenge stability and
                      coordination. Ideal for targeting specific muscle groups,
                      it supports both seated and standing movements.
                    </div>
                  </div>
                </div>
                <div className="flex gap-3 w-full max-sm:flex-col">
                  <div
                    className="bg-gray-300 rounded-xl min-h-20 max-h-32 w-[40%] max-sm:w-full max-sm:min-h-64"
                    style={{
                      backgroundImage: "url(/alat_reformer.jpg)",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  ></div>
                  <div className="w-full pl-10 py-3">
                    <div className="font-semibold">Reformer</div>
                    <div>1 pax</div>
                    <div className="text-justify">
                      The reformer is a Pilates machine that uses springs and a
                      sliding carriage to enhance strength, flexibility, and
                      alignment through low-impact exercises. It’s ideal for all
                      fitness levels.
                    </div>
                  </div>
                </div>
                <div className="flex gap-3 w-full max-sm:flex-col">
                  <div
                    className="bg-gray-300 rounded-xl min-h-20 max-h-32 w-[40%] max-sm:w-full max-sm:min-h-64"
                    style={{
                      backgroundImage: "url(/alat_cadillac.jpg)",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  ></div>
                  <div className="w-full pl-10 py-3">
                    <div className="font-semibold">Cadillac</div>
                    <div>1 pax</div>
                    <div className="text-justify">
                      The Pilates Cadillac is a versatile piece of equipment
                      featuring a raised platform with bars, straps, and
                      springs. It's designed for a wide range of exercises that
                      enhance strength, flexibility, and stability. The Cadillac
                      is ideal for both rehabilitation and advanced workouts,
                      offering support and resistance for full-body conditioning
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="card  p-10 bg-white"
            style={{
              boxShadow: "rgba(0, 0, 0, 0.07) 0px 4px 16px",
            }}
          >
            <h1 className="text-2xl  leading-10 font-bold">Gaia Studio</h1>

            <h3>Pilates studio in Samarinda, Indonesia</h3>

            <div className="my-10">
              <div className="flex flex-col gap-3">
                <div className="flex gap-3">
                  <div>
                    <EnvelopeIcon className="w-5" />
                  </div>
                  <div>gaiastudiopilababes@gmail.com</div>
                </div>
                <div className="flex gap-3">
                  <div>
                    <PhoneIcon className="w-5" />
                  </div>
                  <div>+62 813-5099-4499</div>
                </div>
                <div className="flex gap-3">
                  <div>
                    <MapPinIcon className="w-5" />
                  </div>
                  <div>
                    Jl. Marsda A Saleh Gg. V No.108 BLOK A, Sidomulyo, Kec.
                    Samarinda Ilir, Kota Samarinda, Kalimantan Timur 75116,
                    Indonesia
                  </div>
                </div>
              </div>
            </div>

            <button
              className="btn btn-primary btn-circle w-full"
              onClick={() => {
                window.open(
                  "https://maps.app.goo.gl/2WYwVcVnAFXFjwMv7?g_st=com.google.maps.preview.copy",
                  "_blank"
                );
              }}
            >
              Get Directions
            </button>

            <div className="my-5">
              <div className="uppercase font-bold text-lg">
                Opening hours for this week
              </div>

              <div className="flex flex-col gap-1 my-5">
                <div className="flex items-center justify-between">
                  <div>Monday</div>
                  <div>06:00am - 10:00pm</div>
                </div>
                <div className="flex items-center justify-between">
                  <div>Tuesday</div>
                  <div>06:00am - 10:00pm</div>
                </div>
                <div className="flex items-center justify-between">
                  <div>Wednesday</div>
                  <div>06:00am - 10:00pm</div>
                </div>
                <div className="flex items-center justify-between">
                  <div>Thursday</div>
                  <div>06:00am - 10:00pm</div>
                </div>
                <div className="flex items-center justify-between">
                  <div>Friday</div>
                  <div>06:00am - 10:00pm</div>
                </div>
                <div className="flex items-center justify-between">
                  <div>Saturday</div>
                  <div>06:00am - 10:00pm</div>
                </div>
                <div className="flex items-center justify-between">
                  <div>Sunday</div>
                  <div>06:00am - 10:00pm</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Design;
